@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap');

.app{
  margin: auto;
  width: fit-content;
  text-align: center;
  font-family: 'Mountains of Christmas', cursive;
  color: white;
}

body{
  margin: 0;
  background-color: #b71a3b !important;
}
.mobile {
  display: none;
}

.pc {
  display: block;
}


@media only screen and (max-width: 600px) {
  .mobile {
    display: block;
  }

  .pc {
    display: none;
  }

  .header{
    flex-direction: column;
  }
}
