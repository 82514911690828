.header {
    display: flex;
    width: fit-content;
    margin: auto;
    margin-top: 50px;
}

.header h1 {
    font-size: 64px !important;
}

.header img {
    height: 100px;
    max-width: 100px;
    margin-left: 20px;
    transform: rotate(10deg);
    transition: transform 1s ease-in-out;
}

.header img:hover {
    transform: rotate(360deg);
}

.sigmund_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
