.kalender {
    display: grid;
    width: 800px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .kalender {
        width: 95%;
        grid-template-columns: 1fr 1fr;
    }
}
