.luke {
    border: 4px solid #313c33;
    text-align: center;
    height: 100px;
    background-color: #6a7045;
    margin: 4px;
    position: relative;
}

.luke img {
    max-width: 182px;
    max-height: 92px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.luke p {
    font-size: 64px !important;
}

.luke .modal-content {
    height: 90vh;
}

.modal-body img{
    max-width: 100%;
    max-height: 80vh;
    margin: auto !important;
}

.modal-body-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 32px;
}

.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}


.luke_modal .modal-content {
    background-color: #6a7045;
    border: #313c33 4px solid;
    color: whitesmoke;
}

.grinch {
    animation-name: spin;
    animation-duration: 2000ms;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}