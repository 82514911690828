
select {
    margin: 10px 10px 25px 10px !important;
    width: fit-content;
    padding: 5px 10px;
    background-color: #bc6d4c;
    border: 2px solid  #313c33;
    color: whitesmoke;
    font-size: 24px !important;
}

label {
    margin: auto;
    font-size: 24px;
    width: fit-content;
    font-weight: bold;
}

.kalender-velger-container {
    margin: 25px auto 25px auto;
    padding: 10px 30px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #6a7045;
    border: 4px solid #313c33;
}

.select-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}