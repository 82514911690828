.santa-container {
    position: absolute;
    z-index: -1;
    top: 250px;
    right: 0;
    width: 100vw;
    height: 100px;
}

.santa {
    z-index: -1;
    width: 200px;
    top: 250px;
    animation-name: santa;
    animation-duration: 16s;
    animation-iteration-count: infinite;
}

@keyframes santa {
    0% {margin-left: 90%; margin-right: 10%; margin-top: 0%;}

    25% {margin-top: 25%;}

    45% {  transform: scaleX(1);}
    50% {margin-left: 0%; margin-right: 100%; margin-top: 0%;}
    55% {  transform: scaleX(-1);}

    75% {margin-top: 10%;}

    95% {transform: scaleX(-1);}
    100% {margin-left: 90%; margin-right: 10%; transform: scaleX(1); margin-top: 0%;}
}

.raindeer-container {
    position: absolute;
    z-index: -1;
    bottom: 200px;
    right: 0;
    width: 100vw;
    height: 100px;
}

.raindeer {
    z-index: -1;
    width: 200px;
    top: 250px;
    animation-name: raindeer;
    animation-duration: 20s;
    animation-iteration-count: infinite;
}

@keyframes raindeer {
    0% {margin-left: 90%; margin-right: 10%; margin-top: 0%; transform: rotate(-30deg);}

    25% {margin-top: 15%; transform: rotate(60deg);}

    50% {margin-left: 0%; margin-right: 100%; margin-top: 0%; transform: rotate(-60deg);}

    75% {margin-top: 10%; transform: rotate(30deg);}

    100% {margin-left: 90%; margin-right: 10%; margin-top: 0%; transform: rotate(-30deg);}
}
